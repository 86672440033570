import { useState, useCallback } from 'react';

export const useMergeState = initialState => {
  const [state, setState] = useState(initialState || {});

  const mergeState = useCallback(newState => {
    if (typeof newState === 'function') {
      setState(currentState => ({ ...currentState, ...newState(currentState) }));
    } else {
      setState(currentState => ({ ...currentState, ...newState }));
    }
  }, []);

  return [state, mergeState];
};
