import { useQuery } from './use-query';
import { useMutation } from './use-mutation';

/* eslint-disable react-hooks/rules-of-hooks */
export const useApi = {
  get: (...args) => useQuery(...args),
  post: (...args) => useMutation('post', ...args),
  put: (...args) => useMutation('put', ...args),
  patch: (...args) => useMutation('patch', ...args),
  delete: (...args) => useMutation('delete', ...args)
};
