/**
 * This has to be JS as it's also included in the dnn build (via store.js)
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@utils/api';

// TODO: Migrate to RTK Query for caching
const fetchProductsThunk = createAsyncThunk(
  'productSearchSlice/fetch',
  async ({ apiUrl }, { rejectWithValue }) => {
    try {
      const response = await api.get(apiUrl);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState: {
    entities: {},
    loading: 'idle',
    facetsOpen: false
  },
  reducers: {
    toggleFacetsOpen: (state) => {
      state.facetsOpen = !state.facetsOpen;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsThunk.fulfilled, (state, action) => {
      return {
        ...state,
        loading: 'fulfilled',
        entities: {
          ...action.payload
        }
      };
    });

    builder.addCase(fetchProductsThunk.pending, (state) => {
      state.loading = 'pending';
    });

    builder.addCase(fetchProductsThunk.rejected, (state) => {
      state.loading = 'failed';
    });
  }
});

export const { toggleFacetsOpen } = productSearchSlice.actions;

export function getProducts(dispatch) {
  return async function (item) {
    dispatch(fetchProductsThunk({ apiUrl: item.apiUrl }));
  };
}
