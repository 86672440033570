/* Needs to be JS, since we don't build the store with TS yet */
import { createSlice, isRejected } from '@reduxjs/toolkit';
import { getOrderListsThunk, addItemToOrderListsThunk } from './orderListsThunks';

export const orderListsSlice = createSlice({
  name: 'orderLists',
  initialState: {
    loading: 'idle',
    orderLists: [],
    displayModal: false,
    product: {
      title: '',
      code: '',
      media: [],
      unitOfMeasureCode: '',
      variant: {}
    }
  },
  reducers: {
    displayModal: (state, { payload }) => {
      const {
        title,
        code,
        media = [],
        shouldDisplay,
        unitOfMeasureCode,
        variant
      } = payload;

      return {
        ...state,
        displayModal: shouldDisplay === undefined ? !state.displayModal : shouldDisplay,
        product: {
          title,
          code,
          media,
          unitOfMeasureCode,
          variant
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addItemToOrderListsThunk.fulfilled, (state /* action */) => {
      return state;
    });


    builder.addCase(getOrderListsThunk.fulfilled, (state, action) => {
      const orderLists = action.payload.items || [];
      return {
        ...state,
        orderLists
      };
    });

    builder.addMatcher(
      isRejected(getOrderListsThunk, addItemToOrderListsThunk),
      (state /*, action */) => {
        return {
          ...state,
          loading: 'idle'
        };
      }
    );
  }
});

export const selectOrderLists = state => state.orderLists;

export function getOrderLists(dispatch) {
  return async function () {
    dispatch(getOrderListsThunk());
  };
}

export function addItemsToOrderList(dispatch) {
  return async function ({ variantCode, unitOfMeasureCode, selectedLists }) {
    dispatch(addItemToOrderListsThunk({ variantCode, unitOfMeasureCode, selectedLists }));
  };
}

export function displayOrderListsModal(dispatch) {
  return async function(payload) {
    dispatch(orderListsSlice.actions.displayModal(payload));
  };
}
