export enum AppSecurityScope {
  ACCOUNT_VIEW = 'Account_View',
  ACCOUNT_UPDATE = 'Account_Update',
  CARTS_CREATE = 'Carts_Create',
  CARTS_UPDATE = 'Carts_Update',
  CUSTOMER_VIEW = 'Customer_View',
  CUSTOMER_UPDATE = 'Customer_Update',
  DELIVERY_ADDRESSES_VIEW = 'DeliveryAddresses_View',
  DELIVERY_ADDRESSES_UPDATE = 'DeliveryAddresses_Update',
  EMPLOYEES_VIEW = 'Employees_View',
  EMPLOYEES_UPDATE = 'Employees_Update',
  INVOICES_VIEW = 'Invoices_View',
  ORDER_LISTS_VIEW = 'OrderLists_View',
  ORDER_LISTS_UPDATE = 'OrderLists_Update',
  ORDER_LISTS_LINES_VIEW = 'OrderListLines_View',
  ORDER_LISTS_LINES_UPDATE = 'OrderListsLines_Update',
  ORDERS_VIEW = 'Orders_View',
  SHIPMENTS_VIEW = 'Shipments_View',
  REPORTS_VIEW = 'Reports_View'
}
