export class MetaContext {
  constructor() {
    this.authentication = {
      userName: '',
      isLoggedIn: false
    };

    this.api = {
      baseUri: '/mt-api/'
    };

    typeof window !== 'undefined' && this.getVarsFromDOM();
  }

  getVarsFromDOM() {
    let context = {
      'user': {
        'username': null,
        'isLoggedIn': false
      }
    };

    try {
      const pageMetaContext = JSON.parse(document?.querySelector('[data-component="meta-context"]').textContent);

      context = {
        ...context,
        ...pageMetaContext
      };

      this.authentication = context.user;

    } catch(e) {
      // console.error(e);
    }
  }

  get user() {
    return this.authentication;
  }

  get apiBaseUri() {
    return this.api.baseUri;
  }
}

export const context = new MetaContext();
