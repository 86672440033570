import axios from 'axios';
import HttpStatus from 'http-status';
import { toast } from 'react-toastify';
import { context } from '@services/meta-context';

const api = async (method, url, variables, config = {}) => {
  const requestConfig = {
    url,
    method,
    baseURL: context.api.baseUri,
    timeout: (1000 * 60),
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store'
    },
    withCredentials: true,
    ...config
  };

  const instance = axios.create(requestConfig);

  const responseInterceptor = response => {
    const { title = '', severity = '', code = '' } = response.data;
    if(title && severity) {
      const type = Object.keys(toast.TYPE).includes(severity) ? toast.TYPE[severity] : toast.TYPE.DEFAULT;
      toast(title, {
        type,
        toastId: `${code}`
      });
    }
    return response;
  };


  const errorInterceptor = error => {
    if (error.response.data) {
      const { title = '', severity = '', code = '' } = error.response.data;
      if (title && severity) {
        const type = Object.keys(toast.TYPE).includes(severity) ? toast.TYPE[severity] : toast.TYPE.DEFAULT;
        toast(title, {
          type,
          toastId: `${code}`
        });

        return Promise.reject({
          ...error
        });
      }
    }

    if(!error.response.status) {
      // indicates a non-response error, should go to external logger
      // TODO: translations
      toast.error('Deze functionaliteit werkt op dit moment niet, excuses voor het ongemak', {
        toastId: 'noId'
      });
      return Promise.reject({
        ...error
      });
    }

    switch (error.response?.status) {
    case HttpStatus.UNAUTHORIZED:
      // TODO: translations
      toast.error('Je moet ingelogd zijn om deze actie uit te voeren.', {
        toastId: HttpStatus.UNAUTHORIZED
      });
      return Promise.reject({
        ...error
      });

    case HttpStatus.FORBIDDEN:
      // TODO: translations
      toast.error('Je hebt momenteel geen rechten om deze actie uit te voeren.', {
        toastId: HttpStatus.FORBIDDEN
      });
      return Promise.reject({
        ...error
      });

    case HttpStatus.INTERNAL_SERVER_ERROR:
      // TODO: translations
      toast.error('Er is iets fout gegaan. Probeer het later nog een keer', {
        toastId: HttpStatus.INTERNAL_SERVER_ERROR
      });

      return Promise.reject({
        ...error
      });

    case HttpStatus.BAD_GATEWAY:
      // TODO: translations
      toast.error('Er is netwerk storing. Probeer het later nog een keer', {
        toastId: HttpStatus.BAD_GATEWAY
      });

      return Promise.reject({
        ...error
      });

    default:
      // Let the component handle the error
      return Promise.reject(error);
    }
  };

  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return new Promise((resolve, reject) => {
    const data = {
      ...(method === 'GET' && { params: variables }),
      ...(method !== 'GET' && { data: variables })
    };

    instance.request({ method, url, ...data }).then(
      response => {
        resolve(response);
      },
      error => {
        if (error) {
          reject(error);
        }
        reject(new Error('Something went wrong with the request instance'));
      }
    );
  });
};

export default {
  get: (...args) => api('GET', ...args),
  post: (...args) => api('POST', ...args),
  put: (...args) => api('PUT', ...args),
  patch: (...args) => api('PATCH', ...args),
  delete: (...args) => api('DELETE', ...args)
};
