import { useRef } from 'react';
import isEqual from 'underscore/modules/isEqual';

export const useDeepCompareMemoize = value => {
  const valueRef = useRef();

  if (!isEqual(value, valueRef.current)) {
    valueRef.current = value;
  }
  return valueRef.current;
};
