import { useEffect } from 'react';

export function useClickOutside(ref, callback = () => {}) {
  useEffect(() => {

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);


    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]); //eslint-disable-line react-hooks/exhaustive-deps
}
