import React, { useRef } from 'react';
import objStr from 'obj-str';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useClickOutside } from '@hooks/useClickOutside';
import { useOverflowHidden } from '@hooks/useOverflowHidden';
import styles from './Modal.module.scss';

interface IModalButton {
  label: string;
  onClick: () => void;
  type: string;
}

interface IModal {
  HeaderComponent?: any;
  buttons: IModalButton[];
  children: any;
  fullHeight?: boolean;
  hasFooterSeparator?: boolean;
  onModalClose: () => void;
  title: string;
}

export const Modal = (props: IModal) => {
  const {
    title,
    buttons,
    children,
    hasFooterSeparator = false,
    onModalClose,
    HeaderComponent = null,
    fullHeight = false
  } = props;

  const modalRef = useRef(null);
  const breakpoint = useBreakpoint();
  const isSmallScreen = ['xs', 'sm'].includes(breakpoint);

  useOverflowHidden(true);
  useClickOutside(modalRef, onModalClose);

  if (isSmallScreen) {
    const modalStyles = objStr({
      [styles.fullPage]: true,
      [styles.fullHeight]: fullHeight,
      [styles.overlay]: true
    });

    const scrollPaneStyles = objStr({
      [styles.fullPageScrollpane]: true,
      'py-3': true,
      [styles.fullHeight]: fullHeight
    });

    const contentStyles = objStr({
      [styles.fullPageScrollpaneContent]: true,
      [styles.fullHeight]: fullHeight
    });

    return (
      <div className={modalStyles}>
        <div className={styles.fullPageHeader}>
          <h1 className="h1">{title}</h1>
          <button className="btn btn-link btn-toggle" onClick={onModalClose}>
            <span className="fa fa-times text-lg mr-1"></span>
            <span className="text-sans-serif text-gray-900 text-nowrap">Sluiten</span>
          </button>
        </div>
        <div className={scrollPaneStyles}>
          <div className={contentStyles}>
            {children}
          </div>
        </div>
        <div className={`${hasFooterSeparator ? styles.hasABorder : ''} ${styles.fullPageFooter}`}>
          <div className="d-flex justify-content-end p-3">
            {buttons.map(button => (
              <button
                type="button"
                className={`btn btn-xl btn-${button.type} ml-3`}
                onClick={button.onClick}
                key={`${button.type}-${button.label}`}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      </div>);
  }

  return (
    <div className={`${styles.overlay}`} /*tabIndex="-1" role="dialog" style={{paddingRight: '17px'}}*/>
      <div className="modal-dialog" role="document" ref={modalRef}>
        <div className="modal-content">
          {HeaderComponent ? <HeaderComponent/> : (
            <div className="modal-header">
              <h1 className="h1">{title}</h1>
            </div>
          )}
          {children && (
            <div className="modal-body">
              {children}
            </div>
          )}

          <div className={`modal-footer ${hasFooterSeparator ? styles.hasABorder : ''}`}>
            {buttons.map(button => (
              <button
                type="button"
                className={`btn btn-xl btn-${button.type}`}
                onClick={button.onClick}
                key={`${button.type}-${button.label}`}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
