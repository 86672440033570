import { createSlice, createListenerMiddleware, createSelector } from '@reduxjs/toolkit';

const STORAGE_KEY = '@cl/preferences';

const getInitialState = () => {
  const isServer = typeof window === 'undefined';

  if (!isServer && localStorage?.getItem(STORAGE_KEY) !== null) {
    return JSON.parse(localStorage.getItem(STORAGE_KEY));
  }

  return ({
    displayTax: false
  });
};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: getInitialState,
  reducers: {
    setPreference: (state, { payload } ) => {
      return ({
        ...state,
        ...payload
      });
    }
  }
});


const preferencesMiddleware = createListenerMiddleware();

preferencesMiddleware.startListening({
  actionCreator: preferencesSlice.actions.setPreference,
  effect: async (action, listener) => {
    if(action.type?.indexOf('setPreference')) {
      const state = listener.getState().preferences;
      state && localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }
  }

});

export const selectPreferences = state => state.preferences;
export const selectDisplayTax = createSelector(
  selectPreferences,
  preference => preference.displayTax || false
);

export { preferencesMiddleware };
export const { setPreference } = preferencesSlice.actions;
