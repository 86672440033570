/* Needs to be JS, since we don't build the store with TS yet */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '@utils/api';

export const getOrderListsThunk = createAsyncThunk(
  'orderLists/getOrderLists',
  async (_, { rejectWithValue }) => {
    try {
      // Historically an 'orderlist' is the same entitie as a 'cart'
      const response = await api.get('orderlists');
      return response?.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const addItemToOrderListsThunk = createAsyncThunk(
  'orderlists/bulk/add',
  async ({ variantCode, unitOfMeasureCode, selectedLists }, { rejectWithValue }) => {
    const orderListIds = selectedLists.map((list) => list.orderListId);
    console.log({ variantCode, unitOfMeasureCode, orderListIds });
    const requestBody = {
      orderListIds,
      variantCode,
      unitOfMeasureCode,
      quantity: 0,
      onAccount: true
    };
    try {
      const response = await api.post('orderlists/bulk/add', requestBody);
      return response.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e.message);
    }
  }
);
