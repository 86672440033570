import { createSelector } from '@reduxjs/toolkit';

const selectProductSearch = (state: any) => state?.productSearch;
export const selectProductSearchEntities = (state: any) => state?.productSearch?.entities;
export const selectProductSearchLoading = (state: any) => state?.productSearch?.loading;

export const selectOptions = createSelector(
  selectProductSearchEntities,
  entities => entities.options
);

export const selectFacetsOpen = createSelector(
  selectProductSearch,
  state => state.facetsOpen
);

export const selectSortOrder = createSelector(
  selectProductSearchEntities,
  entities => entities && entities['sort'] // `sort` is a reserved word in JS
);

export const selectPagination = createSelector(
  selectProductSearchEntities,
  entities => entities?.pagination
);

export const selectPaginationItems = createSelector(
  selectProductSearchEntities,
  entities => entities?.pagination?.items
);

export const selectPaginationRelative = createSelector(
  selectProductSearchEntities,
  entities => entities?.pagination?.relative
);


export const selectFilterBadges = createSelector(
  selectProductSearchEntities,
  entities => entities?.active
);

export const selectProducts = createSelector(
  selectProductSearchEntities,
  entities => entities?.results
);

export const selectSearchResultCount = createSelector(
  selectProductSearchEntities,
  entities => entities?.total
);

export const selectFacets = createSelector(
  selectProductSearchEntities,
  entities => entities?.facets
);

export const selectActiveFacets = createSelector(
  selectFacets,
  allFacets => {
    const activeFacets = allFacets.filter((facet: any) => {
      return facet.items.findIndex((item: any) => item.selected === true) !== -1;
    });
    return activeFacets || [];
  }
);

export const selectActiveFacetItems = createSelector(
  selectActiveFacets,
  activeFactes => {
    return activeFactes.map((facet: any) => {
      const selectedItems = facet.items.filter((item: any) => item.selected === true);
      return {
        ...facet,
        items: selectedItems
      };
    });
  }
);

export const selectTiming = createSelector(
  selectProductSearchEntities,
  entities => entities.timing || {}
);
