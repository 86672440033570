import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@utils/api';

const fetchProductsThunk = createAsyncThunk(
  'productsListing/fetch',
  async ({ apiUrl }, { rejectWithValue }) => {
    try {
      const response = await api.get(apiUrl);

      if (response.data.content) {
        const browserTitle = response.data.content.browserTitle;
        const title = response.data.content.title || '';
        const content = response.data.content.content || '';

        if (browserTitle && browserTitle !== '') {
          document.title = `${browserTitle} - Carel Lurvink B.V.`;
        }

        let elem = document.querySelector('h1.field-title');
        if (elem) {
          elem.innerHTML = title;
        }

        elem = document.querySelector('div.field-content');
        if (elem) {
          elem.innerHTML = content;
        }
      }

      if (response.data.correctedAttributes) {
        const newUrl = response.data.pagination?.relative?.self?.urlSet?.web;

        if (newUrl) {
          window.history.replaceState(null, '', `${document.location.origin}${newUrl}`);
        }
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const productsListingSlice = createSlice({
  name: 'productsListing',
  initialState: {
    entities: {},
    loading: 'idle',
    facetsOpen: false
  },
  reducers: {
    toggleFacetsOpen: (state) => {
      state.facetsOpen = !state.facetsOpen;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsThunk.fulfilled, (state, action) => {
      return {
        ...state,
        loading: 'fulfilled',
        entities: {
          ...action.payload
        }
      };
    });

    builder.addCase(fetchProductsThunk.pending, (state) => {
      state.loading = 'pending';
    });

    builder.addCase(fetchProductsThunk.rejected, (state) => {
      state.loading = 'failed';
    });
  }
});

export const { toggleFacetsOpen } = productsListingSlice.actions;

export function getProducts(dispatch) {
  return async function (item) {
    dispatch(fetchProductsThunk({ apiUrl: item.apiUrl }));
    window.history.replaceState(null, '', `${document.location.origin}${item.webUrl}`);
  };
}
