import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import * as gmtActions from '@/features/gmt/gmtActions';
import { selectTiming } from '@components-react/Search/stores/selectors';

const actionsArray = Object.values(gmtActions);

const gtmMiddleware = createListenerMiddleware();
gtmMiddleware.startListening({
  matcher: isAnyOf(...actionsArray),

  effect: (action, { getState /* , dispatch, getOriginalState */ }) => {
    const state = getState();
    const timing = selectTiming(state);
    const gtmEvent = {
      ...action.payload,
      meta: {
        'action.type': action.type,
        createdAt: new Date().toISOString(),
        ...(timing ? { timing } : {})
      }
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(gtmEvent);
  }
});

export { gtmMiddleware };
