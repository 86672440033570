import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { AppSecurityScope } from '@sctypes/AppSecurityScope';
import api from '@utils/api';

interface IUserState {
  username?: string;
  isLoggedIn?: boolean;
  acl?: AppSecurityScope[];
  loading?: 'idle' | 'fulfilled' | 'pending' | 'failed';
}

const initialState = {
  username: null,
  isLoggedIn: false,
  acl: Object.values(AppSecurityScope),
  loading: 'idle'
} as IUserState;

export const getCurrentUserThunk = createAsyncThunk(
  'user/getCurrentUser',
  async () => {
    try {
      const response = await api.get('user');

      const { data = {}} = response;
      if (data?.user?.userName) {
        return {
          username: data.user.userName,
          isLoggedIn: true,
          acl: data.accessControlList
        } as IUserState;
      }
      return initialState;
    } catch (error) {
      return initialState;
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentUserThunk.pending, (state) => {
      return {
        ...state,
        loading: 'pending'
      };
    });

    builder.addCase(getCurrentUserThunk.fulfilled, (state, action) => {
      const { username, isLoggedIn, acl} = action.payload;

      return {
        ...state,
        username,
        isLoggedIn,
        acl,
        loading: 'fulfilled'
      };
    });

    builder.addCase(getCurrentUserThunk.rejected, (state /*, action */) => {
      return {
        ...state,
        loading: 'failed'
      };
    });
  }
});

// Should be AppDispatch, but SXA's store is written in JS
export const getCurrentUser = (dispatch: any) => {
  return async function () {
    dispatch(getCurrentUserThunk());
  };
};

export const selectUser = (state: any) => state.user;
export const selectAcl = createSelector(
  selectUser,
  state => state.acl
);

// Helper functions
// TODO: Find a better place for these
export const Can = (acl: string[], scope: AppSecurityScope) => {
  try {
    return (acl ?? []).includes(scope);
  } catch {
    // The only reason for this try / catch block is React.NET not playing nice
    return false;
  }
};

export const Cannot = (acl: string[], scope: AppSecurityScope) => {
  return !Can(acl, scope);
};
