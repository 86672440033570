import { get } from 'object-path';
import isEqual from 'underscore/modules/isEqual';

// See: https://github.com/reduxjs/redux/issues/303#issuecomment-125184409

const subscribers = {};

export function subscribe(key, callback) {
  const hasSubscription = Object.prototype.hasOwnProperty.call(subscribers, key);

  if (hasSubscription) {
    subscribers[key].push(callback);
  } else {
    subscribers[key] = [callback];
  }

  return function() {
    subscribers[key] = subscribers[key].filter(subscriber => subscriber !== callback);
  };
}


export function createStoreSubscriber(store) {
  let prevState = store.getState();

  const unsubscribe = store.subscribe(() => {
    const newState = store.getState();

    Object.keys(subscribers).forEach(key => {
      const prevValue = get(prevState, key);
      const newValue = get(newState, key);

      if(isEqual(prevValue, newValue)) {
        return false;
      }

      subscribers[key].forEach(cb => cb(newState, prevState));
    });

    prevState = newState;
  });

  return unsubscribe;
}
