import { createAction } from '@reduxjs/toolkit';

export interface IOnsiteSearch {
  searchTerm: string;
  searchResultCount: number;
}
export const onsiteSearch = createAction(
  'gmt/onsiteSearch',
  function prepare({ searchTerm, searchResultCount }: IOnsiteSearch) {
    return {
      payload: {
        event: 'onsite_search',
        search_term: searchTerm,
        search_results: searchResultCount
      }
    };
  });
