import { createSelector, createSlice } from '@reduxjs/toolkit';

export const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState: null,
  reducers: {
    setProduct: (state, { payload }) => {
      return payload;
    },
    setUnitOfMeasure: (state, { payload }) => {
      return {
        ...state,
        unitOfMeasureCode: payload
      };
    }
  }
});

export const selectProduct = state => state.productDetail;

export const selectAlternativeProduct = createSelector(
  selectProduct,
  product => product?.alternativeProducts || [] // Key is plural even though alternatives are a one-to-one relation
);

export const selectVariant = createSelector(
  selectProduct,
  product => product?.variant
);

export const selectUnitOfMeasure = createSelector(
  selectProduct,
  product => {
    if (!product?.unitOfMeasureCode || !product?.variant) {
      return null;
    }

    return product.variant.unitsOfMeasure.find(uom => uom.code === product.unitOfMeasureCode);
  }
);

export const { setProduct, setUnitOfMeasure } = productDetailSlice.actions;
export default productDetailSlice;
