import { useState } from 'react';
import { useBrowserLayoutEffect } from './useBrowserLayoutEffect';

export const useOverflowHidden = (intialHidden = false) => {

  const [isOverflowHidden, setisOverflowHidden] = useState(intialHidden);

  useBrowserLayoutEffect(() => {
    if (!isOverflowHidden) {
      return;
    }

    const originalOverflow = document.body.style.overflow;
    const originalPaddingRight = document.body.style.paddingRight;
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;

    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.overflow = 'hidden';

    return () => {
      if (originalOverflow && originalOverflow !== 'hidden') {
        document.body.style.overflow = originalOverflow;
      } else {
        document.body.style.removeProperty('overflow');
      }


      if (originalPaddingRight) {
        document.body.style.paddingRight = originalPaddingRight;
      } else {
        document.body.style.removeProperty('padding-right');
      }
    };
  }, [isOverflowHidden]);


  useBrowserLayoutEffect(() => {
    if (isOverflowHidden !== intialHidden) {
      setisOverflowHidden(intialHidden);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intialHidden]);

  return [isOverflowHidden, setisOverflowHidden];
};

export default useOverflowHidden;
