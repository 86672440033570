/* eslint-disable no-underscore-dangle */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { productDetailSlice } from '@components/ProductDetail/state/productDetailSlice';
import { orderListsSlice } from '@components-react/OrderLists/state/orderListsSlice';
import { cartSlice } from '@components-react/Cart/cartSlice';
import { preferencesSlice, preferencesMiddleware } from '@components/Preferences/preferencesSlice';
import { productsListingSlice } from '@components-react/ProductOverview/stores/productsListingSlice';
import { productSearchSlice } from '@components-react/Search/stores/productSearchSlice';
import { gtmMiddleware } from '@/features/gmt/gtmMiddleware';
import { createStoreSubscriber, subscribe } from './store-subscriber';
import { userSlice } from './userSlice';

// TODO: Refactor affected components so this hack is no longer needed
/**
 * Please note this is a hack around the Redux paradigm, but required in our current setup
 * See: https://github.com/reduxjs/redux/issues/580#issuecomment-133188511
 * By checking `state.lastAction` in XA.component selective DOM updates can be made
 */
const lastAction = {
  // eslint-disable-next-line no-unused-vars
  reducer: (state = null, action) => action
};

const rootReducer = combineReducers({
  lastAction: lastAction.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [orderListsSlice.name]: orderListsSlice.reducer,
  [preferencesSlice.name]: preferencesSlice.reducer,
  [productDetailSlice.name]: productDetailSlice.reducer,
  [productsListingSlice.name]: productsListingSlice.reducer,
  [productSearchSlice.name]: productSearchSlice.reducer
});


global.storeInstance = global.storeInstance || configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    thunk: true,
    serializableCheck: {
      ignoredPaths: ['lastAction']
    }
  })
    .concat(preferencesMiddleware.middleware)
    .concat(gtmMiddleware.middleware),
  devTools: true
});

const unsubscribe = createStoreSubscriber(global.storeInstance);

export default global.storeInstance;
export { configureStore, subscribe, unsubscribe };
