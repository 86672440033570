import { useState } from 'react';
import { getBreakpoint } from '@utils/getBreakpoint';
import { useBrowserLayoutEffect } from './useBrowserLayoutEffect';

export function useBreakpoint() {

  const [breakpoint, setBreakpoint] = useState(getBreakpoint());

  useBrowserLayoutEffect(() => {
    function breakpoint() {
      setBreakpoint(getBreakpoint());
    }
    window.addEventListener('resize', breakpoint);
    return () => window.removeEventListener('resize', breakpoint);

  }, [setBreakpoint]);

  return breakpoint;
}
