export function getBreakpoint() {
  if(typeof window === 'undefined' || !window) {
    return undefined;
  }

  switch(true) {
  case window.matchMedia('(min-width: 1240px)').matches:
    return 'xl';
  case window.matchMedia('(min-width: 992px)').matches:
    return 'lg';
  case window.matchMedia('(min-width: 768px)').matches:
    return 'md';
  case window.matchMedia('(min-width: 544px)').matches:
    return 'sm';
  default:
    return 'xs';
  }
}
