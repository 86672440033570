export class ImageService {
  getUrl(original: string, width: number, height: number, pad: boolean): string {
    if (!original) {
      return '';
    }

    if (original.includes('hash=')) {
      // Already generated and resized by Sitecore
      return original;
    }

    let result = original;

    if (this.isCloudflare(original)) {
      result = result.replace('/public', '/');
      if (width > 0) {
        result = `${result}w=${width},`;
      }

      if (height > 0) {
        result = `${result}h=${height}`;
      }

      if (pad) {
        result = `${result},fit=pad`;
      }
    } else {
      const separator = original.includes('?') ? '&' : '?';
      result += separator;

      if (width > 0) {
        result += `w=${width}&width=${width}&`;
      }

      if (height > 0) {
        result += `h=${height}&height=${height}&`;
      }
      if (pad) {
        result += 'mode=pad&bgcolor=white';
      }

      // Remove any trailing ampersand (&)
      result = result.replace(/&+$/, '');
    }

    return result;
  }

  isCloudflare(url: string): boolean {
    return url?.includes('/public');
  }
}

const imageService = new ImageService();
export default imageService;
